import { useState, useEffect, useCallback } from 'react';
import { useGlobal } from 'reactn';
import { useSocket } from './Socket';
import { SignalingEvent } from './PeerConnectionEnums';
import uuid from 'uuid';
import { Logger } from '../helpers/Logger';
import { isUnifiedPlanEnabled } from '../helpers/Util';

type PeerConnectionProps = {
  signalingServerUrl: string;
  direction: RTCRtpTransceiverDirection;
  peerConnectionConfig?: RTCConfiguration;
  roomId?: string;
  onStartRemoteStream?: (s: MediaStream) => void;
  onDisconnect?: () => void;
  onReject?: () => void;
};

type RegisterInfo = {
  roomId: string;
  clientId: string;
  onAccept: (message: {}) => void;
};

const deafultIceServers : RTCIceServer[] = [{ urls: 'stun:stun.l.google.com:19302' }];

export const usePeerConnection = (
  props: PeerConnectionProps
): [(stream: MediaStream, onStreamStop: () => void) => void, (onAccept: (message: {}) => void) => void, () => void] => {
  const logger = new Logger(usePeerConnection.name);
  
  const socket = useSocket(props.signalingServerUrl, props.onReject);
  const [peer, setPeer] = useState<RTCPeerConnection | null>(null);
  const [peerConfig, setPeerConfig] = useState<RTCConfiguration>(props.peerConnectionConfig? props.peerConnectionConfig : { iceServers: deafultIceServers });
  const [localStream, setLocalStream] = useState<MediaStream | null>(null);
  const [registerInfo, setRegisterInfo] = useState<RegisterInfo | null>(null);
  const [passcode, setPasscode] = useState<string>('');
  const [isCasting, setIsCasting] = useGlobal<boolean>('isCasting');
  const [iceConnectionState, setIceConnectionState] = useGlobal<RTCIceConnectionState>('iceConnectionState');

  useEffect(() => {
    if (socket) {
      socket.onmessage = (evt: MessageEvent) => { logger.debug(peer); onMessageInner(evt, peer) };
    }
  }, [socket]);

  useEffect(() => {
    function sendRegisterInfo() {
      if (socket && socket.readyState === 1 /*WebSocket.OPEN*/ && registerInfo) {
        const data = {
          type: SignalingEvent.Register,
          room_id: registerInfo.roomId,
          client_id: registerInfo.clientId,
        }
        logger.debug(data);
        socket.send(JSON.stringify(data));
      } else {
        setTimeout(sendRegisterInfo, 200);
      }
    }
  
    if (socket && registerInfo) {
      sendRegisterInfo(); 
    }
  }, [socket, registerInfo]);

  useEffect(() => {
    if (registerInfo && passcode != '') {
      registerInfo.onAccept({ room_id: registerInfo.roomId, passcode: passcode });
    }
  }, [registerInfo, passcode])

  useEffect(() => {
    logger.debug('=== localStream ===', localStream);
    logger.debug('=== peer ===', peer);
    if (localStream && peer) {
      localStream.getTracks().forEach((track) => {
        logger.debug('peer.addTrack():', track);
        peer.addTrack(track, localStream);
      });
    }
  }, [peer, localStream]);

  useEffect(() => {
    const isOffer = (props.direction === 'recvonly');

    if (peer && socket) {
      socket.onmessage = (evt: MessageEvent) => { logger.debug(peer); onMessageInner(evt, peer) };

      if ('ontrack' in peer) {
        peer.ontrack = (event: RTCTrackEvent) => {
          logger.debug('-- peer.ontrack()', event);
          const stream = new MediaStream([event.track]);
          props.onStartRemoteStream && props.onStartRemoteStream(stream);
        }
      } else {
        // @ts-ignore
        peer.onaddstream = (event: MediaStreamEvent) => {
          if (event.stream) {
            const stream = event.stream as MediaStream;
            props.onStartRemoteStream && props.onStartRemoteStream(stream);
          }
        };
      }

      peer.onicecandidate = (event: RTCPeerConnectionIceEvent) => {
        if (event.candidate) {
          logger.debug('-- peer.onicecandidate()', event.candidate);
          const candidate = event.candidate;
          const message = JSON.stringify({ type: SignalingEvent.Candidate, ice: candidate });
          socket.send(message);
        } else {
          logger.debug('empty ice event');
        }
      };

      peer.onnegotiationneeded = async () => {
        logger.debug('peer.onnegotiationneeded()');
        logger.debug(peer);
        try {
          if (isOffer) {
            await makeOffer();
          }
        } catch (error) {
          logger.error('setLocalDescription(offer) ERROR: ', error);
        }
      };

      peer.oniceconnectionstatechange = () => {
        logger.debug(`ICE connection Status has change to ${peer.iceConnectionState}`);
        setIceConnectionState(peer.iceConnectionState);
        switch (peer.iceConnectionState) {
        case 'closed':
        case 'failed':
          if (isOffer) {
            disconnect();
          }
          break;
        }
      };

      peer.onsignalingstatechange = () => {
        logger.debug('signaling state changes:', peer.signalingState);
      };

      if (isUnifiedPlanEnabled()) {
        peer.addTransceiver('video', { direction: props.direction });
      }
    }
  }, [peer, socket]);

  const newPeerConnection = () => {
    const p = new RTCPeerConnection(peerConfig);
    setPeer(p);
    return p;
  };

  const setAnswer = async (sessionDescription: RTCSessionDescription, p: RTCPeerConnection | null) => {
    if (p) {
      try {
        await p.setRemoteDescription(sessionDescription);
        logger.debug('setRemoteDescription(answer) success');
      } catch (error) {
        logger.error('setRemoteDescription(answer) ERROR: ', error);
      }
    } else {
      logger.error('setAnswer(): peer is null');
    }
  };
  
  const makeAnswer = async (p: RTCPeerConnection | null) => {
    if (p) {
      try {
        const answer = await p.createAnswer();
        await p.setLocalDescription(answer);
        const localDescription = p.localDescription;
        if (localDescription) {
          sendSDP(localDescription);
        }
      } catch (error) {
        logger.error('makeAnswer ERROR: ', error);
      }
    } else {
      logger.error('makeAnswer(): peer is null');
    }
  };
  
  const setOffer = async (sessionDescription: RTCSessionDescription, p: RTCPeerConnection | null) => {
    if (p) {
      try {
        await p.setRemoteDescription(sessionDescription);
        logger.debug('setRemoteDescription(offer) success');
        await makeAnswer(p);
      } catch (error) {
        logger.error('setRemoteDescription(offer) ERROR: ', error);
      }
    } else {
      logger.error('setOffer(): peer is null');
    }
  };
  
  const makeOffer = async () => {
    logger.debug('--- makeOffer ---');
    if (peer) {
      try {
        const sessionDescription = await peer.createOffer({
          'offerToReceiveAudio': false,
          'offerToReceiveVideo': true
        });
        logger.debug('createOffer() success in promise, SDP=', sessionDescription.sdp);
        await peer.setLocalDescription(sessionDescription);
        logger.debug('setLocalDescription() success in promise');
        const localDescription = peer.localDescription;
        if (localDescription) {
          sendSDP(localDescription);
        }
      } catch (error) {
        logger.error('makeOffer ERROR: ', error);
      }
    } else {
      logger.error('makeOffer(): peer is null');
    }
  };
  
  const sendSDP = (sessionDescription: RTCSessionDescription) => {
    logger.debug('--- sendSDP ---');
    if (socket && socket.readyState === 1) {
      const message = JSON.stringify(sessionDescription);
      logger.debug(`sending SDP=${message}`);
      socket.send(message);
    } else {
      logger.error('sendSDP: socket is not ready');
    }
  };
  
  const addIceCandidate = (candidate: RTCIceCandidate, p: RTCPeerConnection | null) => {
    logger.debug('--- addICEcandidate', candidate);
    if (p) {
      p.addIceCandidate(candidate);
    } else {
      logger.error('addIceCandidate(): peer is null');
    }
  };
  
  const addStreamStopListener = (stream: MediaStream, callback: () => void) => {
    stream.addEventListener('ended', () => { logger.debug('ended', stream); callback(); }, false);
    stream.getTracks().forEach((track) => {
      track.addEventListener('ended', () => { logger.debug('ended', track); callback(); }, false);
    });
  };

  const onMessageInner = (event: MessageEvent, p: RTCPeerConnection | null) => {
    logger.debug('*** peer ===', peer)
    logger.debug('ws onmessage() data:', event.data);
    const message = JSON.parse(event.data);
    
    switch (message.type) {
      case SignalingEvent.Accept: {
        logger.debug('Receive accept ...');
        setPasscode(message.passcode);
        newPeerConnection();              
        break;
      }
      
      case SignalingEvent.Reject: {
        logger.debug('Received reject ...');
        props.onReject && props.onReject();
        disconnect();
        break;
      }

      case SignalingEvent.Offer: {
        logger.debug('Received offer ...');
        setOffer(message, p);
        break;
      }

      case SignalingEvent.Answer: {
        logger.debug('Received answer ...');
        setAnswer(message, p);
        break;
      }

      case SignalingEvent.Candidate: {
        logger.debug('Received ICE candidate ...');
        const candidate = new RTCIceCandidate(message.ice);
        logger.debug(candidate);
        addIceCandidate(candidate, p);
        break;
      }

      case SignalingEvent.Close: {
        logger.debug('peer is closed ...');
        disconnect();
        break;
      }

      default: {
        logger.debug('Invalid message type:', message.type);
        break;
      }
    }
  }

  const disconnect = () => {
    logger.debug('--- disconnect ---', peer, localStream);
    if (peer) {
      if (peer.iceConnectionState !== 'closed') {
        peer.close();
        
        if (socket && socket.readyState < 2) {
          logger.debug('send close message');
          socket.send(JSON.stringify({ type: SignalingEvent.Close }));
          setTimeout(() => socket.close(), 500);
        }
      }
    }
    if (localStream) {
      localStream.getTracks().forEach((track) => { track.stop(); });
    }
    setLocalStream(null);
    setPeer(null);
    setRegisterInfo(null);
    setPasscode('');
    setIsCasting(false);

    props.onDisconnect && props.onDisconnect();
  };

  const addStream = (stream: MediaStream, onStreamStop: () => void) => {
    if (stream) {
      addStreamStopListener(stream, onStreamStop);
      setLocalStream(stream);
    }
  };

  const register = (onAccept: (message: {}) => void) => {
    logger.debug('--- register ---')
    const info = {
      roomId: props.roomId || uuid.v1(),
      clientId: uuid.v4(),
      onAccept: onAccept,
    };
    setRegisterInfo(info);
  };

  return [addStream, register, disconnect];
};
