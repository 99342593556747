import React from 'react';
import { Logger } from '../helpers/Logger';

type ErrorBoundaryState = {
  error: any
  errorInfo: any
};

export default class ErrorBoundary extends React.Component<{}, ErrorBoundaryState> {
  public state: ErrorBoundaryState = {
    error: null,
    errorInfo: null,
  };

  private logger = new Logger(ErrorBoundary.name);

  constructor(props: {}) {
    super(props);
  }

  static getDerivedStateFromError(error: any) {
    return { error: error };
  }

  componentDidCatch(error: any, info: any) {
    this.logger.error(error, info); 
    this.setState({
      error: error,
      errorInfo: info,
    });
  }

  render() {
    if (!!this.state.error) {
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{whiteSpace: 'pre-wrap'}}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo && this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    return this.props.children;
  }
}