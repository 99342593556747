export class Logger {
  private category: string;

  constructor(_category: string) {
    this.category = _category;
  }

  public debug(...messages: any[]) {
    this.log('info', messages);
  }

  public info(...messages: any[]) {
    this.log('info', messages);
  }

  public warn(...messages: any[]) {
    this.log('warn', messages);
  }

  public error(...messages: any[]) {
    this.log('error', messages);
  }

  private log(msgType: 'debug' | 'info' | 'warn' | 'error', messages: any[]) {
    if (!!process.env.REACT_APP_DEBUG) {
      if (messages.length > 0) {
        console[msgType](this.header(), messages);
      } else {
        console[msgType](this.header());
      }
    }
  }

  private header() : string {
    return `${new Date().toISOString()} [${this.category}]`;
  }
}
