import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import withRoot from './withRoot';
import Home from './containers/Home';
import Room from './containers/Room';
import NoMatch from './components/NoMatch';

const App = ({ classes }: WithStyles<typeof styles>) => {
  return (
    <Router>
      <div className={classes.root}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/rooms/:id" component={Room} />
          <Route component={NoMatch} />
        </Switch>
      </div>
    </Router>
  );
};

const styles = (theme: Theme) => createStyles({
  root: {
    height: '100%',
  },
});

export default withRoot(withStyles(styles)(App));
