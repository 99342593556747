import { useEffect } from 'react';
import { useGlobal } from 'reactn';
import { usePeerConnection } from './PeerConnection';
import { Logger } from '../helpers/Logger';

type P2PNegotiatorProps = {
  direction: RTCRtpTransceiverDirection;
  roomId?: string;
  wsUrl: string;
  isCasting: boolean;
  getDisplayMedia?: () => Promise<MediaStream>;
  onAccept?: (message: {}) => void;
  onReject?: () => void;
  onStartRemoteStream?: (stream: MediaStream) => void;
};

const P2PNegotiator = (props: P2PNegotiatorProps) => {
  const logger = new Logger(P2PNegotiator.name);

  const [addStream, register, disconnect] = usePeerConnection({
    signalingServerUrl: props.wsUrl,
    roomId: props.roomId,
    direction: props.direction,
    onStartRemoteStream: props.onStartRemoteStream,
    onReject: props.onReject,
  });
  const [isCasting, setIsCasting] = useGlobal<boolean>('isCasting');

  useEffect(() => {
    logger.debug('props.isCasting change:', props.isCasting);
    if (props.isCasting) {
      start();
    } else {
      stop();
    }
  }, [props.isCasting]);

  const start = async () => {
    logger.debug('--- P2PNegotiator#start');
    try {
      if (props.getDisplayMedia) {
        const stream = await props.getDisplayMedia();
        addStream(stream, () => {
          logger.debug('stream.onStreamStop callback');
          setIsCasting(false);
        });
      }
      register(props.onAccept? props.onAccept : (message: {}) => {});
      setIsCasting(true);
    } catch (error) {
      logger.error("connect() ERROR: ", error);
      stop();
    }
  };

  function stop() {
    logger.debug('--- P2PNegotiator#stop');
    disconnect();
    setIsCasting(false);
  }

  return null;
}

export default P2PNegotiator;