import React, { useState, useEffect } from 'react';
import { useGlobal } from 'reactn';
import firebase from 'firebase/app';
import 'firebase/auth';
import StyledFirebaseAuth from 'react-firebaseui/FirebaseAuth';
import ScreenCast from './ScreenCast'
import ErrorBoundary from '../components/ErrorBoundary';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import withRoot from '../withRoot';
import { RouteComponentProps } from 'react-router-dom'
import { AppBar, Toolbar, Button, Link, Typography, CircularProgress, Chip } from '@material-ui/core';
import firebaseConfig from '../firebaseConfig';

const Home = ({ classes }: RouteComponentProps & WithStyles<typeof styles>) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [isSignedIn, setIsSignedIn] = useState<boolean>(false);
  const isSupportedBrowser = navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia !== undefined;
  const browserCompatibilityUrl = 'https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getDisplayMedia#Browser_compatibility';
  const [firebaseApp, setFirebaseApp] = useState<firebase.app.App | null>(null);
  const [iceConnectionState, setIceConnectionState] = useGlobal<RTCIceConnectionState>('iceConnectionState');

  const uiConfig : firebaseui.auth.Config = {
    signInFlow: 'redirect',
    signInOptions: [
      firebase.auth.GithubAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => { return false; },
    }
  };

  useEffect(() => {
    const app = !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.apps[0];
    setFirebaseApp(app);

    const unregisterAuthObserver = app.auth().onAuthStateChanged((user) => {
      setIsSignedIn(!!user);
      setLoading(false);
    });

    return () => unregisterAuthObserver();
  }, []);

  return (
    <ErrorBoundary>
      <div className={classes.root}>
        <div className={classes.content}>
          <AppBar position="static" color="default" className={classes.appBar}>
            <Toolbar>
              <img src="./images/icons/favicon-32x32.png"/>
              <Typography variant="h6" color="inherit" className={classes.toolbarTitle} noWrap>
                getdisplay.media
              </Typography>
              {isSignedIn &&
                <Button color="primary" variant="outlined" onClick={() => firebaseApp!.auth().signOut()}>
                  Logout
                </Button>
              }
            </Toolbar>
          </AppBar>
          <main className={classes.main}>
            {loading && <CircularProgress className={classes.loading} />}
            {!loading && !isSignedIn && 
              <div>
                <Typography variant="h4" gutterBottom>
                  Share Your Screen<br/>All you need is a modern browser
                </Typography>
                <img src="./images/icons/mstile-70x70.png"/>
              </div>
            }
            {!loading && !isSignedIn && !isSupportedBrowser &&
              <Typography variant="subtitle1" color="error" gutterBottom>
                This browser DOES NOT support navigator.mediaDevices.getDisplayMedia<br/>
                Check <Link href={browserCompatibilityUrl}>{'Browser Compatibility'}</Link>
              </Typography>
            }
            {!loading && !isSignedIn && isSupportedBrowser &&
              <div>
                <Typography variant="subtitle1" gutterBottom>
                  To start sharing, you need to Sing in.
                </Typography>
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseApp!.auth()} />
              </div>
            }
            {!loading && isSignedIn &&
              <div>
                <Chip label={'Connection State: ' + iceConnectionState} color={iceConnectionState == 'connected' ? 'secondary' : 'default'}/>
                <ScreenCast auth={firebaseApp!.auth()} />
              </div>
            }
          </main>
        </div>
        <footer className={classes.footer}>
          <Typography variant="subtitle1" align="center">
            Powered By <a href="https://github.com/hakobera/serverless-webrtc-signaling-server">Serverless WebRTC Signaling Server</a>
          </Typography>
          <Typography variant="subtitle1" align="center" color="textSecondary" className={classes.copyright}>
            &copy; 2019 hakobera All rights reserved.<br/>
            This software includes the work that is distributed in the <a href="http://www.apache.org/licenses/LICENSE-2.0">Apache License 2.0</a>
          </Typography>
        </footer>
      </div>
    </ErrorBoundary>
  );
};

const styles = (theme: Theme) => createStyles({
  root: {
    textAlign: 'center',
    position: 'relative',
    minHeight: '85vh',
  },

  content: {
    paddingBottom: '3rem',
  },

  main: {
    paddingTop: '3vh',
  },

  appBar: {
    position: 'relative',
  },

  toolbarTitle: {
    flex: 1,
    textAlign: 'left',
    marginLeft: '.5rem',
  },

  footer: {
    padding: theme.spacing.unit * 1,
    borderTop: '1px solid #ccc',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '3rem',
  },

  copyright: {
    fontSize: '.7em',
  },

  loading: {
    margin: theme.spacing.unit * 5,
  },
});

export default withRoot(withStyles(styles)(Home));
