import React from 'react';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles = (theme: Theme) => createStyles({
  success: {
    backgroundColor: green[600],
  },

  error: {
    backgroundColor: theme.palette.error.dark,
  },

  info: {
    backgroundColor: theme.palette.primary.dark,
  },

  warning: {
    backgroundColor: amber[700],
  },

  icon: {
    fontSize: 20,
  },

  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },

  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

type ColoredSnackbarContentProps = {
  classes: {},
  className: string,
  message: React.ReactNode,
  variant: ('success' | 'warning' | 'error' | 'info'),
};


function ColoredSnackbarContent(props: ColoredSnackbarContentProps & WithStyles<typeof styles>) {
  const { classes, className, message, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      {...other}
    />
  );
}

export default withStyles(styles)(ColoredSnackbarContent);
