import React, { useState, useRef, useEffect, useLayoutEffect, useCallback } from 'react';
import { useGlobal } from 'reactn';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import withRoot from '../withRoot';
import { RouteComponentProps } from 'react-router-dom';
import { CircularProgress, Snackbar } from '@material-ui/core';
import P2PNegotiator from '../components/P2PNegotiator';
import ErrorBoundary from '../components/ErrorBoundary';
import ColoredSnackbarContent from '../components/ColoredSnackbarContent';
import 'url-search-params-polyfill';
import { Logger } from '../helpers/Logger';
import { isUnifiedPlanEnabled } from '../helpers/Util'

type RoomProps = {
  id: string;
}

const Room = ({ match, location, classes }: RouteComponentProps<RoomProps> & WithStyles<typeof styles>) => {
  const logger = new Logger(Room.name);

  const [loading, setLoading] = useState<boolean>(true);
  const roomId = match.params.id;
  const passcode = new URLSearchParams(location.search).get('passcode');
  const [wsUrl] = useState<string>(() => {
    const wsBaseUrl = process.env.REACT_APP_SIGNALING_SERVER_URL;
    return `${wsBaseUrl}?auth=__ROOM_AUTH&room_id=${roomId}&passcode=${passcode}`;
  });
  const [remoteStream, setRemoteStream] = useState<MediaStream | null>(null);
  const [isReceiving, setIsReceiving] = useState<boolean>(true);
  const [iceConnectionState] = useGlobal<RTCIceConnectionState>('iceConnectionState');
  const remoteVideoRef = useRef<HTMLVideoElement>(null);
  const [errorInfo, setErrorInfo] = useState<string>('');

  useEffect(() => {
    if (!isUnifiedPlanEnabled()) {
      setIsReceiving(false);
      setLoading(false);
      setErrorInfo('You browser need to support WebRTC Unified Plan. If you are using Safari 12.1 or later, you can enable it in Experimental Features.');
    }
  }, []);

  useLayoutEffect(() => {
    if (remoteVideoRef.current) {
      remoteVideoRef.current.srcObject = remoteStream;
    }
  }, [remoteStream]);

  useEffect(() => {
    if (isReceiving) {
      if (iceConnectionState === 'disconnected' || iceConnectionState === 'failed' || iceConnectionState === 'closed') {
        setRemoteStream(null);
        setIsReceiving(false);
        setErrorInfo('Screen sharing have been stopped.');
      }
    }
  }, [isReceiving, iceConnectionState]);

  const onAccept = () => {
    logger.debug('Accepted to join Room');
  }

  const onReject = () => {
    logger.debug('Rejected');
    setIsReceiving(false);
    setLoading(false);
    setErrorInfo('This room have been full or closed, OR you enter wrong URL.');
  }

  const onStartRemoteStream = (stream: MediaStream) => {
    logger.debug(stream);
    setRemoteStream(stream);
    setLoading(false);
  };
  
  return (
    <ErrorBoundary>
      <div className={classes.root}>
        {loading && <CircularProgress className={classes.loading} />}
        {isReceiving && <video ref={remoteVideoRef} className={classes.remoteVideo} autoPlay playsInline muted controls />}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={!isReceiving}
        >
          <ColoredSnackbarContent
            variant='error'
            message={errorInfo}
            className={classes.snackbar}
          />
        </Snackbar>
        <P2PNegotiator
          direction='recvonly'
          isCasting={isReceiving}
          wsUrl={wsUrl}
          roomId={roomId}
          onAccept={onAccept}
          onReject={onReject}
          onStartRemoteStream={onStartRemoteStream}
        />
      </div>
    </ErrorBoundary>
  );
};

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    color: '#eee',
    backgroundColor: '#333',
    width: '100%',
    height: '100%',
    position: 'relative',
    margin: 0,
    padding: 0,
  },

  loading: {
    margin: theme.spacing.unit * 5,
  },

  remoteVideo: {
    maxWidth: '100%',
    maxHeight: '100%',
  },

  snackbar: {
    fontSize: '1em',
  },
});

export default withRoot(withStyles(styles)(Room))