export const isUnifiedPlanEnabled = () => {
  if (isSafari()) {
    // https://webkit.org/blog/8672/on-the-road-to-webrtc-1-0-including-vp8/
    return RTCRtpTransceiver.prototype.hasOwnProperty('currentDirection') 
  } else {
    const pc = new RTCPeerConnection();
    const config = pc.getConfiguration();
    return ('addTransceiver' in pc) && (!('sdpSemantics' in config) || config['sdpSemantics'] === "unified-plan");
  }
};

const isSafari = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}
