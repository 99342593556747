import { useState, useEffect } from 'react';
import { Logger } from '../helpers/Logger';

export const useSocket = (url: string, onReject?: () => void) => {
  const logger = new Logger(useSocket.name);

  const [socket, setSocket] = useState<WebSocket | null>(null);

  useEffect(() => {
    if (url !== '') {
      if (socket) {
        socket.close();
      }

      const s = new WebSocket(url);
      s.onopen = () => {
        logger.debug(`ws open() to ${url}`);
      };
      s.onerror = (evt) => {
        logger.debug('ws onerror(): ', evt);
        setSocket(null);
      };
      s.onclose = (evt) => {
        // https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent
        switch (evt.code) {
        case 1000:
          logger.debug('ws onclose(): Normal Closure');
          break;

        case 1006:
          logger.debug('ws onclose(): Authorization Failure');
          onReject && onReject();
          break;
      
        default:
          logger.debug('ws onclose(): code=', evt.code);
          break;
        }
        setSocket(null);
      };
      setSocket(s);

      return () => {
        if (s) {
          s.close();
        }
        setSocket(null);
      }
    }
  }, [url]);

  return socket;
};