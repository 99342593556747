import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { useGlobal } from 'reactn';
import firebase from 'firebase/app';
import 'firebase/auth';
import P2PNegotiator from '../components/P2PNegotiator';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { QRCode } from 'react-qrcode-logo';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CircularProgress, Chip, Snackbar, Fab, Card, TextField, CardActions, CardContent, CardMedia } from '@material-ui/core';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import StopScreenShareIcon from '@material-ui/icons/StopScreenShare';
import { Logger } from '../helpers/Logger';

type ScreenCastProps  = {
  auth: firebase.auth.Auth
};

const ScreenCast = ({auth, classes}: ScreenCastProps & WithStyles<typeof styles>) => {
  const logger = new Logger(ScreenCast.name);

  const [localStream, setLocalStream] = useState<MediaStream | null>(null);
  const [wsUrl, setWsUrl] = useState<string>('');
  const [shareUrl, setShareUrl] = useState<string>('');
  const [isCasting, setIsCasting] = useGlobal<boolean>('isCasting');
  const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false);
  const localVideoRef = useRef<HTMLVideoElement>(null);

  useLayoutEffect(() => {
    if (localVideoRef.current) {
      localVideoRef.current.srcObject = localStream;
    }
  }, [localStream]);

  useEffect(() => {
    if (!isCasting) {
      stop();
    }
  }, [isCasting]);

  useEffect(() => {
    return () => stop();
  }, []);

  const getDisplayMedia = () => {
    setLocalStream(null);
    return new Promise<MediaStream>((resolve, reject) => {
      navigator.mediaDevices.getDisplayMedia({ video: true }).then((stream) => {
        setLocalStream(stream);
        resolve(stream);
      }, (error) => {
        setLocalStream(null);
        reject(error);
      });
    });
  };

  const start = async () => {
    logger.debug('start');
    try {
      setShareUrl('');
      setWsUrl(await getSignalingServerUrl());
      setIsCasting(true);
    } catch (error) {
      logger.error('getSignalingServerUrl() ERROR: ', error)
    }
  };

  const stop = () => {
    logger.debug('stop');
    setIsCasting(false);
    setLocalStream(null);
    setShareUrl('');
  }; 

  const onAccept = (message: any) => {
    logger.debug('onAccept', message);
    setShareUrl(`${window.location.protocol}//${window.location.host}/rooms/${message['room_id']}?passcode=${message['passcode']}`);
  };

  const getIdToken = () : Promise<string> => {
    return new Promise((resolve, reject) => {
      if (auth.currentUser) {
        auth.currentUser.getIdToken(true).then((idToken) => {
          resolve(idToken);
        }, (error) => {
          resolve();
        });
      } else {
        reject('Not logged in');
      }
    });
  };

  const getSignalingServerUrl = async () : Promise<string> => {
    const wsBaseUrl = process.env.REACT_APP_SIGNALING_SERVER_URL;
    const token = await getIdToken();
    return `${wsBaseUrl}?auth=${token}`;
  };

  return (
    <div className={classes.root}>
      <P2PNegotiator
        direction='sendrecv'
        isCasting={isCasting}
        wsUrl={wsUrl}
        getDisplayMedia={getDisplayMedia}
        onAccept={onAccept}
      />
      <Card className={classes.card}>
        <CardMedia
          title='preview'
          src='/images/icons/favicon-16x16.png'
        >
          <video ref={localVideoRef} className={classes.previewVideo} autoPlay playsInline muted />
        </CardMedia>
        {isCasting && shareUrl === '' && 
        <CardContent className={classes.shareUrl}>
          <CircularProgress/>
        </CardContent>
        }
        {isCasting && shareUrl !== '' && 
        <CardContent className={classes.shareUrl}>
          <div>
            <QRCode value={shareUrl} size={120} padding={2} />
            <div>
              <CopyToClipboard text={shareUrl} onCopy={() => setIsNotificationOpen(true)}>
                <Chip label='Copy URL' color="primary" />
              </CopyToClipboard>
            </div>
            <form>
              <TextField
                label="Share URL"
                value={shareUrl}
                InputProps={{
                  classes: {
                    input: classes.shareUrlText,
                    multiline: classes.shareUrlBox,
                  },
                }}
                margin="dense"
                variant="outlined"
                multiline
                fullWidth
              />
            </form>
          </div>
        </CardContent>
        }
        <CardActions className={classes.cardActions}>
          {!isCasting &&
            <Fab variant="extended" color="primary" aria-label="Start" className={classes.button} onClick={start} disabled={isCasting}>
              <ScreenShareIcon className={classes.extendedIcon} />
              Start Sharing
            </Fab>
          }
          {isCasting &&  
            <Fab variant="extended" color="secondary" aria-label="Stop" className={classes.button} onClick={stop}>
              <StopScreenShareIcon className={classes.extendedIcon} />
              Stop Sharing
            </Fab>
          }
        </CardActions>
      </Card>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={isNotificationOpen}
        onClose={() => setIsNotificationOpen(false) }
        autoHideDuration={6000}
        message={<span>Copy to Clipboard</span>} />
    </div>
  );
};

const styles = (theme: Theme) => createStyles({
  root: {
    margin: theme.spacing.unit * 2,
    display: 'flex',
    justifyContent: 'center',
  },

  card: {
    width: 360,
  },

  shareUrl: {
    paddingTop: theme.spacing.unit,
    paddingBottom: 0,
  },

  shareUrlBox: {
    paddingTop: '0.6em',
    paddingBottom: '0.6em',
  },

  shareUrlText: {
    height: '3.2em!important',
    fontSize: '0.75em',
  },

  cardActions: {
    textAlign: 'center',
  },

  previewVideo: {
    width: 360,
    height: 140,
    backgroundColor: '#000',
  },

  button: {
    margin: theme.spacing.unit,
    width: '100%',
  },
  
  extendedIcon: {
    marginRight: theme.spacing.unit * 2,
  },
});

export default withStyles(styles)(ScreenCast);